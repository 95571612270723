import smoothscroll from 'smoothscroll-polyfill'
import { createLogger } from './features/logger/logger'
import { fedopsCreator } from './features/logger/clientLoggerFactory'
import { BIReporterImpl } from './features/bi/bi'
import { initThunderboltContainer } from './init/initThunderbolt'
import { fixViewport } from './lib/fixViewport'
import './assets/scss/viewer.global.scss' // Only import it so it will be written in manifest.json
import { FetchApi } from './features/client-fetch/client-fetch'
import { featuresLoaders } from './featureLoaders'
import { instance as biInstance } from './bi-module/instance'
import { IThunderboltClient } from './features/thunderbolt/IThunderbolt'
import { createFeaturesLoader } from '@wix/thunderbolt-features'
import {
	clientModuleFetcher,
	createClientSiteAssetsClientFactory,
	getFallbackOverrideStrategy,
	shouldRouteStagingRequest,
} from 'thunderbolt-site-assets-client'
import { tbElementComponents, thunderboltComponents } from './componentLibraries'
import { BeatEventType } from '@wix/thunderbolt-symbols'
import { ClientFetchCache } from './features/client-fetch/fetch-cache'
import { taskify, fedopsMetricsReporter } from '@wix/thunderbolt-commons'
import { createDomReadyPromise } from './features/thunderbolt/DomReady'

taskify(() => smoothscroll.polyfill())

// @ts-ignore
const { viewerModel, Sentry } = window

const reportBI = biInstance.reportBI.bind(biInstance)
const sendBeat = biInstance.sendBeat.bind(biInstance)
const setDynamicSessionData = biInstance.setDynamicSessionData.bind(biInstance)
const reportPageNavigation = biInstance.reportPageNavigation.bind(biInstance)
const reportPageNavigationDone = biInstance.reportPageNavigationDone.bind(biInstance)

reportBI('main loaded') // TODO: Report using logger

const runThunderbolt = async () => {
	await Promise.resolve(window.onBeforeStart)
	const {
		experiments,
		viewMode,
		requestUrl,
		siteAssets: {
			clientTopology,
			manifests,
			dataFixersParams,
			siteScopeParams,
			beckyExperiments,
			staticHTMLComponentUrl,
			remoteWidgetStructureBuilderVersion,
		},
		fleetConfig,
		deviceInfo,
		mode: { qa: qaMode },
	} = viewerModel

	const logger = await taskify(() =>
		createLogger({
			fedopsCreator,
			sentryInstance: Sentry,
			wixBiSession: biInstance.wixBiSession,
			viewerModel,
		})
	)

	const fetchFn = window.fetch
	const fetchApi = FetchApi(viewerModel, fetchFn, ClientFetchCache())

	const biReporter = BIReporterImpl(
		reportBI,
		sendBeat,
		setDynamicSessionData,
		reportPageNavigation,
		reportPageNavigationDone
	)
	const featuresLoader = createFeaturesLoader(featuresLoaders, { experiments })

	const siteAssetsClient = createClientSiteAssetsClientFactory({
		fetchFn,
		siteAssetsMetricsReporter: fedopsMetricsReporter(logger),
		clientTopology,
		timeout: 4000,
		manifests,
		dataFixersParams,
		requestUrl,
		siteScopeParams,
		moduleFetcher: clientModuleFetcher(fetchFn, clientTopology, manifests),
		isStagingRequest: shouldRouteStagingRequest(fleetConfig),
		fallbackOverride: getFallbackOverrideStrategy(viewerModel.experiments, 'client'),
		beckyExperiments,
		staticHTMLComponentUrl,
		remoteWidgetStructureBuilderVersion,
		deviceInfo,
		qaMode,
	})

	// ORDER DOES MATTER!!! DO NOT CHANGE!!!
	const componentLibraries = () =>
		window.ThunderboltElementsLoaded.then(() => [
			thunderboltComponents(),
			tbElementComponents(require('thunderbolt-elements')),
		])

	const thunderboltInitPayload = {
		wixBiSession: biInstance.wixBiSession,
		viewerModel,
		biReporter,
		siteAssetsClient,
		fetchApi,
		specificEnvFeaturesLoaders: featuresLoader,
		componentLibraries,
		logger,
		experiments,
		browserWindow: window,
		warmupData: createDomReadyPromise().then(() => window.warmupData || {}),
	}

	const initThunderbolt = await taskify(() => {
		logger.phaseMark('load site features')
		return initThunderboltContainer(thunderboltInitPayload)
	})
	const thunderboltClient: IThunderboltClient = await taskify(() => {
		logger.phaseMark('init Thunderbolt')
		return initThunderbolt()
	})
	const { firstPageId } = await taskify(() => {
		logger.phaseMark('client render')
		return thunderboltClient.render()
	})

	if (viewMode === 'mobile') {
		await taskify(() => fixViewport())
	}
	biReporter.sendBeat(BeatEventType.PAGE_FINISH, 'page interactive', { pageId: firstPageId })
	logger.appLoaded()
}

runThunderbolt()
